import React from 'react';
import { INavigationLink } from '@wings-shared/layout';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import { MapPointerIcon, SettingIcon, PhoneIcon } from '@uvgo-shared/icons';

export const sidebarMenus: INavigationLink[] = [
  {
    to: '',
    title: 'Vendors',
    icon: <StoreOutlinedIcon />,
  },
  {
    to: 'vendor-location',
    title: 'Vendor Location',
    icon: <MapPointerIcon />,
  },
  {
    to: 'vendor-pricing',
    title: 'Vendor Pricing',
    icon: <StoreOutlinedIcon />,
  },
  {
    to: 'vendor-contact',
    title: 'Contacts',
    icon: <PhoneIcon />,
  },
  {
    to: 'settings',
    title: 'Settings',
    icon: <SettingIcon />,
  },
  {
    to: 'approvals',
    title: 'Approvals',
    icon: <SettingIcon />,
  }
];