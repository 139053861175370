import {
  GRID_ACTIONS,
  GridPagination,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  UIStore,
  Utilities,
  cellStyle,
  SelectOption,
  regex,
} from '@wings-shared/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import React, { FC, useEffect, useState } from 'react';
import { ContactMasterStore, SettingsStore, VendorLocationStore } from '../../../../../Stores';
import { NavigateFunction, useParams } from 'react-router';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import { styles } from '../../UpsertLocation.styles';
import { useBaseUpsertComponent } from '@wings/shared';
import { useAgGrid, CustomAgGridReact, useGridState } from '@wings-shared/custom-ag-grid';
import {
  SETTING_ID,
  VENDOR_LOCATION_COMPARISON_FILTERS,
  VendorLocationModel,
  VmsModuleSecurity,
} from '../../../../Shared';
import { gridFilters } from '../../../fields';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { ColDef, GridOptions, RowNode } from 'ag-grid-community';
import { ChildGridWrapper, CollapsibleWithButton, ConfirmDialog } from '@wings-shared/layout';
import { ServiceCommunicationPreferenceModel } from '../../../../Shared/Models/ServiceCommunicationPreference.model';
import { COLLECTION_NAMES } from '../../../../Shared/Enums/CollectionName.enum';
import { VALIDATION_REGEX } from '../../../../Shared/Enums/Spacing.enum';


interface Props {
  classes: IClasses;
  settingsStore?: SettingsStore;
  vendorLocationStore?: VendorLocationStore;
  contactMasterStore?: ContactMasterStore;
  navigate: NavigateFunction;
  locationData: VendorLocationModel;
  setServiceCommGridDisabled: React.Dispatch<React.SetStateAction<Boolean>>;
  isNewLocationContactAdded: boolean;
  setIsNewLocationContactAdded: React.Dispatch<React.SetStateAction<Boolean>>;
  locationGridDisabled: boolean;
}

const ServiceCommunicationGrid: FC<Props> = ({
  classes,
  settingsStore,
  contactMasterStore,
  locationData,
  vendorLocationStore,
  setServiceCommGridDisabled,
  isNewLocationContactAdded,
  setIsNewLocationContactAdded,
  locationGridDisabled,
}) => {
  const params = useParams();
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_LOCATION_COMPARISON_FILTERS, ServiceCommunicationPreferenceModel>(
    gridFilters,
    gridState
  );
  const useUpsert = useBaseUpsertComponent<ServiceCommunicationPreferenceModel>(params, null, undefined);
  const unsubscribe = useUnsubscribe();
  const [ selectedVendorLocation, setSelectedVendorLocation ] = useState(locationData);

  const isEditable = (): boolean => useUpsert.isEditable || useUpsert.isDetailView;

  useEffect(() => {
    loadVendorServiceCommData();
    loadInitialData();
    setServiceCommGridDisabled(gridState.isRowEditing);
  }, [ isNewLocationContactAdded ]);

  const loadInitialData = () => {
    const locationId = params.locationId;
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(parseInt(`${locationId}`))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        setSelectedVendorLocation(response);
      });
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Communication Service',
      minWidth: 150,
      field: 'serviceComm',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'serviceComm'),
      headerTooltip:'Communication Service',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Communication Service',
        valueGetter: (option: SelectOption) => option.value,
        getAutoCompleteOptions: () => settingsStore?.vendorContactCommunicationService,
        rules: 'required',
      },
    },
    {
      headerName: 'Attention',
      field: 'attention',
      headerTooltip:'Attention',
      cellEditorParams: {
        placeHolder: 'Attention',
        ignoreNumber: true,
        rules: `string|between:2,100|regex:${regex.alphaNumericWithCommaSpace}|regex:${VALIDATION_REGEX.TWO_WHITESPACE_STRING}`,
      },
    },
    {
      headerName: 'Usage Type',
      field: 'contactUsegeType',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name,
      comparator: (current, next) => Utilities.customComparator(current, next, 'contactUsegeType'),
      headerTooltip:'Usage Type',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Usage Type',
        getAutoCompleteOptions: () => settingsStore?.vendorContactUsageType,
        valueGetter: (option: SelectOption) => option.value,
        rules: 'required',
      },
    },
    {
      headerName: 'Vendor Location Contact',
      minWidth: 100,
      field: 'vendorLocationContact',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.label || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'vendorLocationContact'),
      headerTooltip:'Vendor Location Contact',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Vendor Location Contact',
        getAutoCompleteOptions: () => contactMasterStore?.vendorLocationContactList,
        valueGetter: (option: SelectOption) => option.value,
        rules: 'required',
      },
    },
    {
      headerName: 'Status',
      minWidth: 100,
      field: 'status',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'status'),
      headerTooltip:'Status',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Status',
        getAutoCompleteOptions: () => settingsStore?.vendorContactStatus,
        valueGetter: (option: SelectOption) => option.value,
        rules: 'required',
      },
    },
    {
      headerName: 'Access Level',
      minWidth: 100,
      field: 'accessLevel',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'accessLevel'),
      headerTooltip:'Access Level',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Access Level',
        getAutoCompleteOptions: () => settingsStore?.vendorAccessLevel,
        valueGetter: (option: SelectOption) => option.value,
        rules: 'required',
      },
    },
    ...agGrid.auditFields(gridState.isRowEditing),
    {
      field: 'actionRenderer',
      suppressNavigable: true,
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      suppressMenu: true,
      suppressMovable: true,
      hide: !isEditable(),
      suppressSizeToFit: true,
      minWidth: 150,
      maxWidth: 150,
      cellStyle: { ...cellStyle() },
    },
  ];

  const upsertNewGrid = () => {
    setServiceCommGridDisabled(true);
    const data = [ new ServiceCommunicationPreferenceModel() ];
    agGrid.addNewItems(data, { startEditing: false, colKey: 'serviceComm' });
    gridState.setHasError(true);
  };

  const loadVendorServiceCommData = (pageRequest?: IAPIGridRequest) => {
    gridState.setIsProcessing(false);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocationContact.VendorLocation.VendorLocationId',
          propertyValue: params.id,
        },
      ]),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    const locationRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: params.id,
        },
      ]),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    UIStore.setPageLoader(true);

    forkJoin([
      contactMasterStore?.getVMSComparison(COLLECTION_NAMES.SERVICE_COMM, request),
      contactMasterStore?.getVMSComparison(COLLECTION_NAMES.VENDOR_LOCATION_CONTACT, locationRequest),
      settingsStore?.getSettings( SETTING_ID.SETTINGS_CONTACT_STATUS, 'ContactStatus'),
      settingsStore?.getSettings( SETTING_ID.SETTING_USAGES_TYPE, 'UsageType'),
      settingsStore?.getSettings( SETTING_ID.SETTING_ACCESS_LEVEL, 'AccessLevel'),
      settingsStore?.getSettings( SETTING_ID.SETTING_COMMUNICATION_SERVICE, 'Communication Services'),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
          setIsNewLocationContactAdded(false);
          gridState.setIsRowEditing(false);
        })
      )
      .subscribe((response: [IAPIPageResponse<ServiceCommunicationPreferenceModel>]) => {
        UIStore.setPageLoader(false);
        gridState.setPagination(new GridPagination({ ...response[0] }));
        gridState.setGridData(response[0].results);
        const allowSelectAll = response[0].totalNumberOfRecords <= response[0].pageSize;
        gridState.setAllowSelectAll(allowSelectAll);
        agGrid.reloadColumnState();
        agGrid.refreshSelectionState();
      });
  };

  const onInputChange = (): void => {
    gridState.setIsAllRowsSelected(true);
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  const onDropDownChange = (): void => {
    gridState.setIsAllRowsSelected(true);
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const saveRowData = (rowIndex: number) => {
    upsertServiceCommGrid(rowIndex);
  };

  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: { onInputChange, onDropDownChange },
      columnDefs,
      isEditable: VmsModuleSecurity.isEditable,
      gridActionProps: {
        isActionMenu: VmsModuleSecurity.isEditable && isEditable(),
        showDeleteButton: false,
        getEditableState: ({ data }: RowNode) => {
          return !Boolean(data.id);
        },
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !VmsModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
          },
        ],
        getDisabledState: () => gridState.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => {
          switch (action) {
            case GRID_ACTIONS.EDIT:
              agGrid._startEditingCell(rowIndex, 'serviceComm');
              setServiceCommGridDisabled(true);
              break;
            case GRID_ACTIONS.SAVE:
              saveRowData(rowIndex);
              break;
            case GRID_ACTIONS.CANCEL:
              getConfirmation(rowIndex);
              break;
          }
        },
      },
    });
    return {
      ...baseOptions,
      pagination: false,
      suppressScrollOnNewData: true,       
      isExternalFilterPresent: () => false,
      onCellDoubleClicked: ({ rowIndex, colDef }) => {
        agGrid._startEditingCell(rowIndex, colDef.field);
        setServiceCommGridDisabled(true);
      },
    };
  };

  const upsertServiceCommGrid = (rowIndex: number): void => {
    gridState.gridApi.stopEditing();
    gridState.setIsProcessing(true);
    setServiceCommGridDisabled(false);
    const model = agGrid._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    contactMasterStore
      ?.upsertServiceCommGrid(model.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
          gridState.setIsAllRowsSelected(false);
        })
      )
      .subscribe({
        next: (response: ServiceCommunicationPreferenceModel) => {
          agGrid._updateTableItem(rowIndex, response);
        },
        error: error => {
          agGrid._startEditingCell(rowIndex, 'serviceComm');
          setServiceCommGridDisabled(true);
          useUpsert.showAlert(error.message, model.id.toString());
        },
      });
  };

  const getConfirmation = (rowIndex: number): void => {
    gridState.isAllRowsSelected
      ? ModalStore.open(
        <ConfirmDialog
          title="Confirm Changes"
          message={'Cancelling will lost your changes. Are you sure you want to cancel?'}
          yesButton="Confirm"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => {
            ModalStore.close();
            cancelEditing(rowIndex);
          }}
        />
      )
      : cancelEditing(rowIndex);
  };

  const cancelEditing = (rowIndex: number) => {
    agGrid.cancelEditing(rowIndex);
    agGrid.filtersApi.resetColumnFilters();
    gridState.setIsAllRowsSelected(false);
    setServiceCommGridDisabled(false);
  };

  return (
    <div className={`${classes.root} ${locationGridDisabled ? `${classes.disabledGrid}`: ''} `}>
      <CollapsibleWithButton
        title="Service Communication Preference"
        buttonText="Associate Service Contact"
        isButtonDisabled={UIStore.pageLoading || gridState.isRowEditing}
        onButtonClick={() => upsertNewGrid()}
        titleVariant="h6"
        hasPermission={VmsModuleSecurity.isEditable}
      >
        <ChildGridWrapper hasAddPermission={false}>
          <CustomAgGridReact
            isRowEditing={gridState.isRowEditing}
            rowData={gridState.data}
            gridOptions={gridOptions()}
            serverPagination={true}
            paginationData={gridState.pagination}
            onPaginationChange={loadVendorServiceCommData}
            classes={{ customHeight: classes.customHeight }}
            disablePagination={gridState.isRowEditing || gridState.isProcessing}
          />
        </ChildGridWrapper>
      </CollapsibleWithButton>
    </div>
  );
};

export default inject('settingsStore', 'contactMasterStore')(withStyles(styles)(observer(ServiceCommunicationGrid)));
