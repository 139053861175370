export enum APPROVALS_COMPARISON_FILTERS {
    VENDOR = 'Vendor',
    AirportCode = 'AirportCode',
    ENTITY_NAME = 'Entity Name',
    APPROVAL_STATUS = 'Status',
  }

export enum APPROVAL_MERGE_STATUS {
    NOT_MERGED = 1,
    MERGED=2,
    FAILED=3,
    COMPLETED=4
  }
