import {
  GRID_ACTIONS,
  IAPIGridRequest,
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  SEARCH_ENTITY_TYPE,
  UIStore,
  Utilities,
} from '@wings-shared/core';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { SettingsStore, VendorLocationStore, VendorManagementStore, VendorUserStore } from '../../../../Stores';
import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import { styles } from '../UpsertVendor.styles';
import { CityModel, CountryModel, StateModel, VIEW_MODE, useBaseUpsertComponent } from '@wings/shared';
import { useGridState } from '@wings-shared/custom-ag-grid';
import {
  SETTING_ID,
  SettingBaseModel,
  StatusBaseModel,
  VendorLocationModel,
  VendorManagmentModel,
  VmsModuleSecurity,
} from '../../../Shared';
import { useUnsubscribe } from '@wings-shared/hooks';
import { addUserFields, addUserEmailField } from './Fields';
import ViewInputControls from '../../../Shared/Components/ViewInputControls/ViewInputControls';
import { EDITOR_TYPES, IGroupInputControls } from '@wings-shared/form-controls';
import { finalize, takeUntil } from 'rxjs/operators';
import { VendorAddressModel } from '../../../Shared/Models/VendorAddress.model';
import { DetailsEditorHeaderSection, DetailsEditorWrapper, ConfirmNavigate, ConfirmDialog } from '@wings-shared/layout';
import { SettingNamesMapper } from '../../../../Stores/SettingsMapper';
import { ENVIRONMENT_VARS, EnvironmentVarsStore } from '@wings-shared/env-store';
import { UserModel } from '../../../Shared/Models/User.model';
import { PrimaryButton } from '@uvgo-shared/buttons';
import CustomTooltip from '../../../Shared/Components/Tooltip/CustomTooltip';
import { VendorUserResponseModel } from '../../../Shared/Models/VendorUserResponse.model';
import { VendorUserModel } from '../../../Shared/Models/VendorUser.model';
import { OperationInfoSettingOptionModel } from '../../../Shared/Models/OperationInfoSettingOptionModel.model';
import { UserGroupModel } from '@wings/user-management/src/Modules';

interface Props {
  classes: IClasses;
  settingsStore: SettingsStore;
  vendorManagementStore: VendorManagementStore;
  searchFilters: IAPISearchFiltersDictionary;
  vendorLocationStore: VendorLocationStore;
  vendorUserStore: VendorUserStore;
  navigate: NavigateFunction;
  setSelectedVendor: React.Dispatch<React.SetStateAction<VendorManagmentModel>>;
  params?: { vendorId: string; vendorName: string; vendorUserId: number };
}

const UpsertVendorUser: FC<Props> = ({
  classes,
  settingsStore,
  vendorManagementStore,
  vendorLocationStore,
  vendorUserStore,
  searchFilters,
  setSelectedVendor,
}) => {
  const params = useParams();
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const [ isEmailField, setIsEmailField ] = useState(true);

  const useUpsert = useBaseUpsertComponent<VendorUserModel>(params, addUserFields, searchFilters);

  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const isEditable = useUpsert.isEditable && VmsModuleSecurity.isEditable;
  const [ isValidEmail, setIsValidEmail ] = useState(true);
  const [ existingOktaUser, setExistingOktaUser ] = useState(null);
  const [ vendorUserRole, setVendorUserRole ] = useState([]);
  const [ userStatus, setUserStatus ] = useState([]);

  useEffect(() => {
    useUpsert.setViewMode((params.viewMode.toUpperCase() as VIEW_MODE) || VIEW_MODE.DETAILS);
    loadLocationData();
    loadUplinkGroups();
  }, []);

  const loadLocationData = (pageRequest?: IAPIGridRequest) => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: params.vendorId,
        },
      ]),
    };
    vendorLocationStore?.getVMSComparison(request).subscribe();
  };

  const loadUplinkGroups = () => {
    vendorUserStore.loadUplinkOktaGroups().subscribe((response)=>{
      setVendorUserRole(response.results)
    });
    settingsStore.getVendorUserStatus().subscribe((response)=>{
      setUserStatus(response)
    });
  };
  const title = (): string => {
    return 'Add New User';
  };

  const groupInputControlsEmail = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'email',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
        ],
      },
    ];
  };
  const groupInputControlsUser = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'email',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: true,
          },
          {
            fieldKey: 'givenName',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'surName',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'userRole',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorUserRole,
          },
          {
            fieldKey: 'vendorUserLocation',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorLocationStore.getOperationalEssentialSettingOptions<VendorLocationModel>(
              vendorLocationStore.vendorLocationList,
              'vendorLocation'
            ),
            // options: vendorLocationStore.vendorLocationList,
            multiple: true,
          },
          {
            fieldKey: 'status',
            type: EDITOR_TYPES.DROPDOWN,
            options: StatusBaseModel.deserializeList(userStatus),
          },
          {
            fieldKey: 'userId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
        ],
      },
    ];
  };

  const setFormValue = response => {
    response = VendorUserModel.deserialize(response);
    useUpsert.setFormValues(response);
    useUpsert.getField('givenName').set(response.firstName);
    useUpsert.getField('surName').set(response.lastName);
    useUpsert.getField('userId').set(response.userId);
  };

  const findExistingEmail = (): void => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      searchCollection: JSON.stringify([
        {
          propertyName: 'email',
          propertyValue: useUpsert.getField('email').value,
        },
      ]),
    };
    UIStore.setPageLoader(true);
    vendorUserStore
      .checkIfUserExist(request, useUpsert.getField('email').value)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(
        (response: any) => {
          if (response.results.length > 0) {
            setFormValue(response.results[0]);
            setExistingOktaUser(response.results[0]);
            vendorUserStore.isDataAvailable = true;
          }
          setIsEmailField(false);
        },
        error => {
          if (error.response?.data.IsSuccess === false) {
            return useUpsert.showAlert(error.response?.data.Summary, '0');
          } else useUpsert.showAlert(error.message, '0');
        }
      );
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'email':
        useUpsert.getField('email').isValid ? setIsValidEmail(false) : setIsValidEmail(true);
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const dialogHeader = (): ReactNode => {
    return useUpsert.viewMode === VIEW_MODE.NEW ? 'Add Vendor' : title();
  };

  const headerActions = (): ReactNode => {
    return (
      <DetailsEditorHeaderSection
        title={<CustomTooltip title={title()} />}
        backNavTitle="Vendors"
        hideActionButtons={false}
        disableActions={!formRef.changed || !formRef.isValid || formRef.hasError}
        backNavLink="/vendor-management"
        isEditMode={isEditable}
        hasEditPermission={VmsModuleSecurity.isEditable}
        onAction={action => onAction(action)}
        showStatusButton={false}
        isActive={true}
      />
    );
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'userRole':
        const filteredList = vendorUserStore.uplinkOktaGroups.filter(item => {
          return (
            item?.name.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setVendorUserRole(filteredList);
        break;
      case 'vendorUserLocation':
        const locationRequest: IAPIGridRequest = {
          searchCollection: JSON.stringify([
            {
              propertyName: 'Name',
              propertyValue: searchValue
            }
          ]),
          filterCollection: JSON.stringify([
            {
              propertyName: 'Vendor.Id',
              propertyValue: params.vendorId,
            },
          ]),
        };
        vendorLocationStore?.getVMSComparison(locationRequest).subscribe()
        break;
      case 'status':
        const filteredUserStatus = settingsStore?.status.filter(item => {
          return (
            item?.name.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setUserStatus(filteredUserStatus);
        break;
      default:
        break;
    }
    return;
  };

  const onAction = (action: GRID_ACTIONS): void => {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        navigate(`/vendor-management/upsert/${params.vendorId}/${params.vendorCode}/edit/vendor-email/upsert/new/`);
        useUpsert.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.SAVE:
        saveUserData();
        break;
      default:
        getConfirmation();
        break;
    }
  };

  const saveUserData = () => {
    if (existingOktaUser) {
      generateTempPass();
    } else {
      upsertOktaUser();
    }
  };

  const upsertOktaUser = () => {
    const selectedUserGroup: UserGroupModel = useUpsert.getField('userRole').value;
    const userId: string = useUpsert.getField('userId').value;
    UIStore.setPageLoader(true);
    const request = new UserModel();
    vendorManagementStore
      .createNewUser(request.serialize(useUpsert.form.values(), selectedUserGroup.id, userId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(
        response => {
          upsertNewVendorUser(response?.TempPassword);
        },
        error => {
          if (error.response?.data.IsSuccess === false) {
            return useUpsert.showAlert(error.response?.data.Summary, '0');
          } else useUpsert.showAlert(error.message, '0');
        }
      );
  };

  const generateTempPass = () => {
    UIStore.setPageLoader(true);
    vendorUserStore
      .generateTempPassword(useUpsert.form.values().email)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {})
      )
      .subscribe(
        (response) => {
          updateOktaGroup(response)       
        },
        error => {
          UIStore.setPageLoader(false)
          if (error.response?.data.IsSuccess === false) {
            return useUpsert.showAlert(error.response?.data.Summary, '0');
          } else useUpsert.showAlert(error.message, '0');
        }
      );
  };

  const updateOktaGroup = (tempPassword) => {
    const selectedUserGroup: UserGroupModel = useUpsert.getField('userRole').value;
    const ids: string[] = [ selectedUserGroup.id ];
    const userId: string = useUpsert.getField('userId').value;

    UIStore.setPageLoader(true);
    const request = new UserModel();
    vendorUserStore
      .updateOktaGroup(request.serialize(useUpsert.form.values(), ids, userId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(
        () => {
          upsertNewVendorUser(tempPassword);
        },
        error => {
          if (error.response?.data.IsSuccess === false) {
            return useUpsert.showAlert(error.response?.data.Summary, '0');
          } else useUpsert.showAlert(error.message, '0');
        }
      );
  };

  const upsertNewVendorUser = (tempPassword) => {
    UIStore.setPageLoader(true);
    const selectedUserGroup: UserGroupModel = useUpsert.getField('userRole').value;
    const userId: string = useUpsert.getField('userId').value;
    // const ids: string[] =  [ selectedUserGroup.id ];
    const request = new VendorUserModel({ ...useUpsert.form.values() });
    request.vendorUserLocation = OperationInfoSettingOptionModel.deserializeList(
      useUpsert.getField('vendorUserLocation').value
    );
    vendorUserStore
      ?.upsertVendorUser(request.serialize(0, params.vendorId, selectedUserGroup.name, userId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorUserModel) => {
          AlertStore.info(`Email link has been sent successfully to ${useUpsert.form.values().email}.`);
          vendorManagementStore.sendNewEmail(useUpsert.form.values().email,tempPassword,params.vendorId);
          
          navigate(
            `/vendor-management/upsert/${params.vendorId}/${params.vendorCode}/${useUpsert.viewMode}/vendor-user`
          );
        },
        error: error => {
          useUpsert.showAlert(error.message, request.id);
        },
      });
  };

  const getConfirmation = (): void => {
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Changes"
        message={'Cancelling will lost your changes. Are you sure you want to cancel?'}
        yesButton="Confirm"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          ModalStore.close();
          navigate(
            `/vendor-management/upsert/${params.vendorId}/${
              params.vendorCode
            }/${useUpsert.viewMode.toLocaleLowerCase()}/vendor-user`
          );
        }}
      />
    );
  };

  return (
    <ConfirmNavigate isBlocker={false}>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={isEditable}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <ViewInputControls
            isEditable={isEditable}
            groupInputControls={isEmailField ? groupInputControlsEmail() : groupInputControlsUser()}
            onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
            onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
            field={fieldKey => useUpsert.getField(fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
            // onFocus={fieldKey => onFocus(fieldKey)}
          />
          {useUpsert.viewMode !== VIEW_MODE.DETAILS &&
            (isEmailField ? (
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={() => findExistingEmail()}
                disabled={isValidEmail}
              >
                Next
              </PrimaryButton>
            ) : (
              ''
            ))}
        </div>
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'vendorManagementStore',
  'vendorUserStore'
)(withStyles(styles)(observer(UpsertVendorUser)));
