import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }) =>
  createStyles({
    headerActions: {
      justifyContent: 'space-between',
      '& button':{
        backgroundColor:'#005295!important',
        color:'#ffffff',
        '&:hover':{
          backgroundColor:'#003968!important'
        }
      },
      '& .MuiTypography-h6':{
        width: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'normal',
        whiteSpace: 'nowrap'
      }
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: spacing(2),
      marginBottom: spacing(1)
    },
    customHeight: {
      height: 'calc(100vh - 290px)'
    },
    editorWrapperContainer: {
      overflow: 'auto'
    },
    gridHeight:{
      paddingBottom:'70px'
    }
  });