import { regex } from '@wings-shared/core';
import { auditFields } from '@wings/shared';

export const addUserEmailField = {
  ...auditFields,
  email: {
    label: 'Email ID*',
    rules: `required|string|between:2,200|regex:${regex.email}`,
  },
}
export const addUserFields = {
  ...auditFields,
  id: {
    label: 'Vendor Id',
  },
  email: {
    label: 'Email ID*',
    rules: `required|string|between:2,200|regex:${regex.email}`,
  },
  givenName: {
    label: 'First Name*',
    rules: 'required|string|between:1,50',
  },
  surName: {
    label: 'Surname*',
    rules: 'required|string|between:1,50',
  },
  userRole:{
    label: 'User Role*',
    rules: 'required'
  },
  vendorUserLocation:{
    label: 'Location',
  },
  status:{
    label: 'Status*',
    rules: 'required'
  },
  userId:{
    label: 'UserId',
  }
}